import React, {createRef} from 'react';
import Layout from 'components/common/layout/layout';
import Hero from "components/common/cover/hero";
import Servicio from "components/servicios/servicio";
import Contacto from "components/common/contacto/Contacto";
import theme from "styles/style";
import {Porque} from "../../components/servicios/porque";
import Contact from "components/common/form";
import Reviews from "../../components/common/reviews/reviews";
import {ServiciosSlider} from "../../components/servicios/serviciosSlider";
import Slider from "../../components/common/Slider/Slider";
import {graphql} from "gatsby";
import { SEO } from 'components/common/SEO';
import {CameraBG} from 'components/home/Content/occss-home';


const Index = ({data}) => {
    const start = createRef(null);
    const block_1 = createRef(null);
    const block_2 = createRef(null);
    const block_3 = createRef(null);
    const block_4 = createRef(null);
    const block_5 = createRef(null);
    const end = createRef(null);

    const adjust = {
        start: { left: 10, color: `${theme.orange}`, size: 400, animation: false, center: false},
        block_1: { left: 25, color: `${theme.blue}`, size: 300, animation: true, center: true},
        block_2: { left: 0, color: `${theme.blue}`, size: 300, animation: false, center: false},
        block_3: { left: 45, color: `${theme.blue}`, size: 300, animation: false, center: false},
        block_4: { left: 0, color: `${theme.green}`, size: 300, animation: false, center: false},
        block_5: { left: 0, color: `${theme.green}`, size: 200, animation: true, center: true},
    };

    return (
      <>
      <SEO
            title = "Servicios de producción de vídeo y fotografía en Zaragoza"
            description= "Encuentra el servicio que mejor se adapta a tus necesidades en Obtura. Producimos vídeos para cualquier tipo de empresa, profesional o evento."
            location = 'servicios'
            canonical = 'servicios'
            robots = 'index, follow'
        />
        <Layout start={start} block_1={block_1} block_2={block_2} block_3={block_3} block_4={block_4} block_5={block_5} end={end} adjust={adjust}>
            <Hero to={"#OBTURA-PRODUCTORA-AUDIOVISUAL-VIDEO-Y-FOTOGRAFÍA"} src={data.sanityHeroPages.codeVideo__landscape} vsrc={data.sanityHeroPages.codeVideo__portrait}  title={"Servicios"} />
            <div className={"container padding-xl"} id={"OBTURA-PRODUCTORA-AUDIOVISUAL-VIDEO-Y-FOTOGRAFÍA"} ref={start}>
                <Servicio title={"OBTURA PRODUCTORA AUDIOVISUAL VIDEO Y FOTOGRAFÍA"} to={"#contactar"} text={"COMENZAR VÍDEO"} color={theme.orange} isDown={true} >
                    <p>
                        <b>¿Necesitas los servicios de una productora audiovisual? ¿Tienes pensado recurrir a la fotografía o al vídeo corporativo? Sea cual sea el proyecto que tienes en mente, en Obtura queremos ayudarte.</b>
                    </p>
                    <p>
                         Lo haremos paso a paso: primero identificaremos tus necesidades y nos pondremos en marcha cuando estén totalmente claras.
                    </p>
                    <p>
                        ¡Descubre en esta página todos los servicios audiovisuales que podemos cubrir! Estamos totalmente convencidos de que tenemos el que tú necesitas.
                    </p>
                </Servicio>
            </div>
            <span className={"container"} ref={block_1} id={"block_1"}></span>
            <ServiciosSlider services={data.allSanityService.edges} />
            <CameraBG className={"padding-xl"}>
              <div className={"container"} ref={block_2}>
                  <h2 className={"h1 putDot--blue"}>
                      ACERCAMOS LA PRODUCCIÓN DE VÍDEO Y FOTOGRAFÍA
                  </h2>
                  <div className={"grid-x2"}>
                      <div>
                          <p>
                              ¡En Obtura el tamaño no importa! Estamos preparados para asumir tus retos audiovisuales seas una gran empresa o una pyme que busca dar el salto definitivo a la digitalización. Así que esperamos que la próxima llamada en la oficina sea la tuya. 
                          </p>
                          <p>
                              Y es que tenemos una misión muy clara: que cualquiera pueda acceder a servicios que hoy en día son fundamentales en la estrategia y el marketing de cualquier empresa. Daremos a tu imagen un valor añadido para diferenciarte de la competencia con los medios más avanzados. Sabemos que el servicio que necesitas está entre nuestro porfolio de soluciones: búscalo y nos ponemos manos a la obra.
                          </p>
                      </div>
                  </div>
              </div>
            </CameraBG>
            <div ref={block_3} className={"padding-xl"}>
                <h2 className={"h1 text-center putDot"} ref={block_5}>
                    Trabajos recientes
                </h2>
                <Slider works={data.allSanityPortfolio.edges}/>
            </div>
            <div className={"container padding-xl"} ref={block_4}>
                <Porque />
            </div>
            <section className="padding-xl">
              <div className={"container"} >
                  <h2 className={"h1 text-center putDot"} ref={block_5}>
                      PODEMOS AYUDARTE CON TU ESTRATEGIA AUDIOVISUAL
                  </h2>
              </div>
              <div className={"container"} >
                  <div className={"grid-x2"}>
                      <div>
                          <Reviews />
                      </div>
                      <div>
                  <Contact />
                      </div>
                  </div>
              </div>
              <div className={"container"} id={"contactar"} ref={end}>
                  <Contacto />
              </div>
          </section>
        </Layout>
        </>
    )
}

export const query = graphql`
  query ServicesSliderPageQuery {
      allSanityPortfolio(
        limit: 5, sort: {order: DESC, fields: publishedAt}
      ) {
        edges {
          node {
            codeVideo
            title
            mainImage {
              ... ImageWithPreview
            }
            publishedAt
            slug {
              current
            }
            categories {
              title
            }
            services {
              h1__title
            }
          }
        }
      }
      allSanityService {
        edges {
          node {
            id
            _rawText
            seo_title
            seo_description
            slug {
              current
            }
            mainImage {
              ... ImageWithPreview
            }
            h1__title
            h2__subservices
            h2__title2
          }
        }
      }
        sanityHeroPages(pages: {eq: "servicios"}) {
            codeVideo__portrait
            codeVideo__landscape
        }
  }
`

export default Index
