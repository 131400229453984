import React from 'react';

export const Porque = () => {
    return (
        <section>
            <div>
                <h2 className={"h1"} >¿POR QUÉ OBTURA ES TU PRODUCTORA DE VÍDEO?</h2>
                <div className={"grid-x3"}>
                    <div className={"relative"}>
                        <h3 className={"title__circle title__circle--blue"}>CREATIVIDAD</h3>
                        <p>
                            Porque nos gusta lo fresco y lo original, pero es que además lo llevamos a cabo. No nos quedamos únicamente en un mero deseo. Pensamos diferente para cada proyecto.
                        </p>
                    </div>
                    <div className={"relative"}>
                        <h3 className={"title__circle title__circle--green"}>EXPERIENCIA</h3>
                        <p>
                            Estamos en continua formación para trabajar en el medio audiovisual. Conocemos y manejamos los formatos más actuales y modernos. Trabajamos en este sector desde el primer día de nuestra trayectoria.
                        </p>
                    </div>
                    <div className={"relative"}>
                        <h3 className={"title__circle title__circle--orange"}>CALIDAD Y PRECIO</h3>
                        <p>
                            Valoramos mucho nuestro trabajo por la satisfacción de ver los resultados. Nuestros precios se ajustan al valor y a la calidad del trabajo. ¿Podemos adaptarnos a tu proyecto? ¡Habla con nosotros y lo analizamos! 
                        </p>
                    </div>
                    <div className={"relative"}>
                        <h3 className={"title__circle title__circle--orange"}>MOVILIDAD</h3>
                        <p>
                            Estamos ubicados en Zaragoza pero para nosotros es habitual trabajar en otros puntos de España y nos encanta hacerlo. Solo tenemos que meter el equipo en la mochila y encontrarnos contigo donde nos pidas.
                        </p>
                    </div>
                    <div className={"relative"}>
                        <h3 className={"title__circle title__circle--blue"}>ADAPTACIÓN Y FLEXIBILIDAD</h3>
                        <p>
                           Trabajamos en un sector donde las noches, los fines de semana o los días festivos pueden ser las mejores jornadas para una sesión. Y no nos importa en absoluto: es una pata más de nuestro trabajo.
                        </p>
                    </div>
                    <div className={"relative"}>
                        <h3 className={"title__circle title__circle--green"}>CERCANÍA</h3>
                        <p>
                            En el momento que te conviertes en nuestro cliente, para nosotros eres prácticamente un amigo o amiga más. Nos gusta llamarte y ayudarte con todas tus necesidades audiovisuales. ¡No nos trates de usted, por favor!
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Porque;
